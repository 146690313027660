<template>
  <section class="section-creativity">
    <projects-banner :headerHeight="295" :detailPage="true" />
    <template>
      <section class="py-lg-16 py-sm-16">
        <v-container fluid class="py-lg-16 px-lg-16">
          <v-row>
            <v-col>
              <h1
                class="text-center text-lg-h3 text-h5 font-weight-bold pb-lg-10 py-8 pt-sm-0 pb-sm-10 pt-lg-0"
                :style="{ color: $routeMeta.meta.active.color }"
              >
                {{ $t("projects.ourProjects") }}
              </h1>
            </v-col>
          </v-row>
          <v-row class="px-16 mt-0">
            <v-col
              cols="12"
              xs="12"
              sm="6"
              lg="4"
              class="pa-0"
              v-for="project in projectData"
              :key="project.image_url"
            >
              <template>
                <v-card
                  class="mx-auto my-lg-12 my-8 rounded-lg elevation-1"
                  max-width="374"
                  height="370"
                >
                  <v-img
                    height="250"
                    :src="project.image_url"
                    class="image-page elevation-0"
                  >
                    <v-container fluid fill-height class="page ma-0 pa-0">
                      <v-row justify="center">
                        <a
                          :href="project.url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn elevation="0" class="rounded-lg" width="110">
                            <span class="font-weight-bold btn btn-projects">
                              {{ $t("projects.btnShow") }}
                            </span>
                          </v-btn>
                        </a>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-card-title class="font-weight-bold project-name">{{
                    project.name
                  }}</v-card-title>

                  <v-card-text>
                    <div class="my-n4 font-weigth-light text-body-2">
                      {{ project.details }}
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <v-chip-group
                      active-class=""
                      column
                      class=""
                    >
                      <v-chip
                        v-for="technology in project.technology"
                        :key="technology.id"
                        class="name-tecnologia"
                        :color="technology.color"
                        :text-color="technology.textColor"
                        ><span
                          >{{ technology.title }}</span
                        ></v-chip
                      >
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="py-lg-16 py-sm-16 odd">
        <v-container class="pb-16 px-0 ma-0 slider-container">
          <v-row>
            <v-col>
              <h1
                class="text-center text-lg-h3 text-h5 font-weight-bold py-lg-16 py-8 pt-sm-0 pb-sm-14"
                :style="{ color: $routeMeta.meta.active.color }"
              >
                {{ $t("projects.ourClients") }}
              </h1>
            </v-col>
          </v-row>
          <v-row class="clients mt-0">
            <v-col>
              <div class="sliders-container" v-if="firstHalfData.length">
                <Slider :client-data="firstHalfData"  direction="left"/>
                <Slider :client-data="secondHalfData" direction="right"/>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </template>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import serviceProjects from "../services/projects";
import serviceTechnologies from "../services/technologies";
import serviceClients from "../services/clients";
import Slider from "../components/Slider.vue"

export default {
  name: "Projects",
  metaInfo() {
    return { title: this.$t("projects.ourProjects") };
  },
  components: { Slider },
  data: () => ({
    projectData:[],
    technologyData: [],
    clientData: [],
    firstHalfData: [],
    secondHalfData: [],
    
    //Slider configuration
    slickOptions2: {
      arrows: false,
      autoplay: true,
      slidesToShow: 5,
      infinite: true,
      autoplaySpeed: 0,
      speed: 4000,
      cssEase: "linear",
      loop: true,
      responsive: [
        {
          breakpoint: 1265,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1264,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 2
          }
        }
      ]
    },
    slickOptions3: {
      slidesToShow: 5,
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 4000,
      cssEase: "linear",
      rtl: true,
      loop: false,
      responsive: [
        {
          breakpoint: 1265,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1264,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 2
          }
        }
      ]
    },
    // End slider configuration

    projects: [
      {
        src: "/projects/Dazi.png",
        name: "Dazi Solar",
        description: "Landing Page",
        technologies: ["WordPress", "JavaScript"],
        href: "https://dazisolar.com/",
        class: "ml-lg-auto mr-lg-0 mx-sm-5"
      },
      {
        src: "/projects/legalcrew.png",
        name: "Legal Crew",
        description: "Landing Page",
        technologies: ["WordPress", "JavaScript"],
        href: "https://legalcrew.do/",
        class: "mx-sm-5 mx-lg-auto"
      },
      {
        src: "/projects/Gingerly.png",
        name: "Gingerly",
        description: "E-commerce",
        technologies: ["Shopify", "JavaScript"],
        href: "https://gingerlyshop.com/",
        class: "mr-lg-auto ml-lg-0 mx-sm-5"
      },
      {
        src: "/projects/drsanchez.png",
        name: "Dr Sánchez Acosta",
        description: "Landing Page",
        technologies: ["WordPress", "JavaScript"],
        href: "https://drsanchezacosta.com/",
        class: "ml-lg-auto mr-lg-0 mx-sm-5"
      },
      {
        src: "/projects/Crystaldo.png",
        name: "Crystal",
        description: "E-commerce",
        technologies: ["Shopify", "JavaScript"],
        href: "https://crystal.do/",
        class: "mx-sm-5 mx-lg-auto"
      },
      {
        src: "/projects/Thenailbar.png",
        name: "The Nailbar Beauty",
        description: "E-commerce",
        technologies: ["Shopify", "JavaScript"],
        href: "https://www.thenailbarbeauty.com/",
        class: "mr-lg-auto ml-lg-0 mx-sm-5"
      },
      {
        src: "/projects/Finisterre.png",
        name: "Finisterre",
        description: "Landing Page",
        technologies: ["WordPress"],
        href: "https://finisterre.do/",
        class: "ml-lg-auto mr-lg-0 mx-sm-5"
      },
      {
        src: "/projects/Paseo27.png",
        name: "Paseo27",
        description: "landing page",
        technologies: ["WordPress"],
        href: "https://paseo27.do/",
        class: "mx-sm-5 mx-lg-auto"
      },

      {
        src: "/projects/Nonecold.png",
        name: "Nonecold",
        description: "E-commerce Custom",
        technologies: ["Vue.js", "Laravel"],
        href: "https://nonecold.com/",
        class: "mr-lg-auto ml-lg-0 mx-sm-5"
      },
      {
        src: "/projects/DWenvios.png",
        name: "Dominican Watchman",
        description: "Web Site",
        technologies: ["WordPress"],
        href: "https://dwnenvios.com.do/",
        class: "ml-lg-auto mr-lg-0 mx-sm-5"
      }
    ],
    logos: [
      {
        src: "/logos/Crystal.png"
      },
      {
        src: "/logos/Nonecold.png"
      },
      {
        src: "/logos/Finisterre.png"
      },
      {
        src: "/logos/Gingerly.png"
      },
      {
        src: "/logos/GPCapital.png"
      },
      {
        src: "/logos/GrupoPais.png"
      },
      {
        src: "/logos/LaVie.png"
      },
      {
        src: "/logos/Nailbar.png"
      },
      {
        src: "/logos/drsanchez.png"
      },
      {
        src: "/logos/legalcrew.png"
      },
      {
        src: "/logos/Offitek.png"
      },
      {
        src: "/logos/Paseo27.png"
      },
      {
        src: "/logos/Rijosa.png"
      },
      {
        src: "/logos/DMW.png"
      }
    ],
    model: null
  }),
  computed: {
    ...mapGetters(["getTechnology"])
  },
  mounted: async function() {
    const resultClient = await serviceClients.getClients();
    this.clientData = resultClient.data;
    
    const resultProjects = await serviceProjects.getProjects();
    this.projectData = resultProjects.data;
    
    const resultTech = await serviceTechnologies.getTechnologies();
    this.technologyData = resultTech.data;

    // divides the clients data into two halfs so that it can be sent as
    // props for both the sliders, it should work with pair and inpair numbers
    //     ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓

    const midpoint = Math.ceil(this.clientData.length / 2);

    for (let i = 0; i < this.clientData.length; i++) {
      if (i < midpoint) {
        this.firstHalfData.push(this.clientData[i]);
      } else {
        this.secondHalfData.push(this.clientData[i]);
      }
    }
  }
};
</script>