
<template>
    <div class="slider-container">
        <div ref="slider" :class="`${direction === 'left' ? 'left' : 'right'} keen-slider`">
            <div
                v-for="item in clientData"
                class="keen-slider__slide number-slide"
                :key="`image-slider-card-first-${item.id}`"
            >
                <v-card
                    class="px-2 transparent"
                    height="200"
                    width="200"
                    style="max-width: 200px; min-width: 200px"
                    :elevation="0"
                >
                    <v-img
                    :src="item.image_url"
                    height="100%"
                    class="rounded-circle"
                    ></v-img>
                </v-card>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import "keen-slider/keen-slider.min.css";
  import KeenSlider from "keen-slider";
  
  const animation = { duration: 50000, easing: (t) => t };
  
  export default {
    name: "Slider",
    props: {
        clientData: Array,
        direction: String,
    },
    mounted: function () {
        const data = this.clientData;

        this.slider = new KeenSlider(this.$refs.slider, {
            // setting of the slider
            // here's the documentation for future references "https://keen-slider.io/docs#event-hooks"
            //         ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓  ↓ 
            loop: true,
            renderMode: "performance",
            drag: false,
            rtl: this.direction === 'left' ? false : true,
            slides: {
                perView: 5,
                spacing: 7,
            },
            created(s) {
                s.moveToIdx(data.length, true, animation);
            },
            updated(s) {
                s.moveToIdx(s.track.details.abs + data.length,
                true, animation);
            },
            animationEnded(s) {
                s.moveToIdx(s.track.details.abs + data.length,
                true, animation);
            },
        });
    },
    beforeDestroy() {
      if (this.slider) this.slider.destroy();
    },
  };
  </script>
  
  <style scoped>
  .number-slide {
    display: flex;
    background-color: #f5f5f5;
    align-items: center;
    justify-content: center;

    margin: 3.5px 0;

    font-size: 50px;
    font-weight: 500;

    color: #fff;

    height: 200px;
    width: 200px;
    max-height: 100vh;
  }

  </style>